@import './global.scss';

.dark {
    --color-page-background: var(--lightBg);
    --color-text: var(--lightTxt);
}

.container {
    width: 85%;
    margin: auto;
}

nav {
    margin: 0 2vw 0px;
    display: flex;
    justify-content: space-around;
    min-height: 8vh;
    align-items: center;
    background-color: transparent;
}

.logo {
    color: var(--lightTxt);
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 20px;
}

.nav-space {
    min-width: 20rem;
}

.nav-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
}

.nav-links li {
    list-style: none;
    padding: 21px;
}

.nav-links li::before {
    content: "";
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: var(--lightTxt);
    transition: all 0.2s linear;
}

.nav-links li:hover::before {
    width: 100%;
}

.nav-links a {
    font-weight: 600;
    color: var(--lightTxt);
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 14px;
}

.burger {
    display: none;
}

.burger div {
    width: 20px;
    height: 3px;
    background-color: var(--lightTxt);
    margin: 5px;
    transition: all 0.3s ease;
}

@media screen and (max-width:1024px) {
    .nav-links {
        width: 60%;
    }
}

@media screen and (max-width:768px) {
    body {
        overflow-x: hidden;
    }

    .nav-links {
        position: absolute;
        height: 100%;
        top: 8vh;
        right: 0;
        background-color: var(--lightBg);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: translateX(100%);
        transition: transform 0.2s ease-in;
    }

    .nav-links li {
        opacity: 0;
        list-style: none;
    }

    .nav-links.nav-active li {
        animation: navLinkFade 0.3s ease forwards 0.3s;
    }

    .burger {
        display: block;
    }
}

.nav-active {
    transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);

}

// Switch Styles

#theme-switch {
    display: none;
}
input[type=checkbox] {
    height: 0;
    width: 0;
    display: none;
}

label {
    text-indent: -9999px;
    width: 45px;
    height: 28px;
    background: var(--lightBg);
    display: flex;
    justify-content: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    position: relative;
    border: 2px solid var(--lightTxt)
}

label:after {
    content: '';
    background: var(--lightTxt);
    width: 15px;
    height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 4px;
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms;
}

input:checked+label:after {
    left: calc(100% - 5px);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms !important;
    transition-delay: 0 !important;
}

/* Banner Styles */
section.banner h2 {
    display: inline;
    color: var(--lightTxt);
    font-size: 80px;
    letter-spacing: 5px;
    text-align: justify;
    text-transform: capitalize;
    text-align: center;
    line-height: 100px;
    font-weight: 600;
}

h2 .my-photo {
    height: 70px;
    border-radius: 50%;
}

section.banner {
    text-align: center;
    margin-top: 2rem;
    padding: 0 .5rem;
}

section.skills {
    text-align: center;
    margin-top: 2rem;
}

.skills-wrap {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ececec;
    border-radius: 15px 15px 0px 15px;
    margin-top: 50px;
    border: 0.2px solid var(--lightTxt);
}

.skills-wrap img {
    height: 60px;
    margin: 10px 20px;
}

.skills-heading {
    position: absolute;
    bottom: -25px;
    right: -1px;
    color: var(--lightBg);
    background: var(--lightTxt);
    border-radius: 0 0 25px 10px;
    padding: 5px 20px 10px 20px;
}
.break {
    flex-basis: 100%;
    height: 0;
}
.experience-img {
    height: 0px;
    width: auto;
}

.experience-list{   
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: -1px;
    padding-top: 1.5vw;
    padding-bottom: 3.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    background-color: #ececec;
    color: var(--lightBg);
    text-decoration: none;
}
.exp-container {
    background-color: #ececec;
    margin-top: 50px;
}