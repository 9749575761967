@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    cursor: none;
}

.font-cursive{font-family: 'Merienda', cursive;}
.font-high{font-family:  'Itim', cursive;}

html {
    background: var(--lightBg);
    --lightBg: #000000;
    --lightTxt: #ececec;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    overflow-x: hidden;
    position: relative;
}

html[data-theme='light'] {
    background: var(--lightBg);
    --lightBg: #ececec;
    --lightTxt: #000000;
}

html[data-theme='dark'] {
    background: var(--lightBg);
    --lightTxt: #ececec;
    --lightBg: #000000;
}

#body-wrap{
    // min-height: 1500px;
}

.w-container{
    width: 100%;
}

.courser,
.courser-lg,
.courser-eye-line {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    mix-blend-mode: difference;
    left: 0px;
    top: 0px;
    pointer-events: none;
    z-index: 9999;
}

.courser-lg {
    transition: all .2s cubic-bezier(0.1, 0.25, 0.3, 1);
}

.courser-eye-line .curson-eye {
    width: 2rem;
    height: 2rem;
    background: transparent;
    mix-blend-mode: difference;
    border: 0.2px solid var(--lightTxt);
    transform: rotate(45deg);
    border-radius: 75% 15%;
    z-index: 9999;
    pointer-events: none;
    transition: transform 600ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, opacity 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, background 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s !important;
    margin-left: -15px;
    margin-top: -16px;
}

.courser .curson-eye {
    width: 9px;
    height: 9px;
    background: var(--lightTxt);
    mix-blend-mode: difference;
    border-radius: 50%;
    overflow: visible;
    transform: scale(1.5);
    z-index: 9999;
    pointer-events: none;
    transition: transform 600ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, opacity 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, background 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s !important;
    margin-left: -3px;
    margin-top: -4px;
}

.courser-lg .curson-eye {
    width: 2.1rem;
    height: 2.1rem;
    background: transparent;
    mix-blend-mode: difference;
    border: 0.2px solid var(--lightTxt);;
    transform: scale(2);
    opacity: 0.5;
    position: absolute;
    border-radius: 50%;
    z-index: 9999;
    pointer-events: none;
    transition: transform 600ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, opacity 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s, background 300ms cubic-bezier(0.1, 0.25, 0.3, 1) 0s !important;
    margin-left: 0px;
    margin-top: 0px;
}
